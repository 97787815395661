/******* screen-large.less *******/
/******* mixins.less 2013-1-16 *******/
.clearfix {
  *zoom: 1;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: '';
}
.clearfix:after {
  clear: both;
}
@media only screen and (min-width: 1101px) {
  /******* hide-mobile.less 2013-1-16 *******/
  #mobile-navi,
  .togglenavigation,
  .cb-mobile-access,
  .cb-mobile-navigation,
  .body-mobile {
    display: none;
  }
  .cb-mobile-navigation {
    opacity: 0;
    -o-transition: none;
    -moz-transition: none;
    -webkit-transition: none;
    transition: none;
  }
  .cb-mobile-invisible,
  .body-non-mobile {
    display: block;
  }
  /******* navigation-large.less 2013-1-16 *******/
  .navi,
  .navi > .item,
  .dock {
    display: block;
    float: left;
  }
  .menu {
    display: block;
  }
  .cb-navi-sticky,
  .cb-navi-sticky-clone {
    width: 100%;
  }
  .cb-navi-sticky > .navi,
  .cb-navi-sticky-clone > .navi {
    position: relative;
    width: 87.890625%;
    margin-left: 6.0546875%;
  }
  .cb-navi-sticky .navi.sub2,
  .cb-navi-sticky-clone .navi.sub2 {
    position: relative;
  }
  .cb-navi-sticky .navi.sub2:before,
  .cb-navi-sticky-clone .navi.sub2:before {
    content: "";
    position: absolute;
    top: 12px;
    left: -40px;
    background: url(/images/logo_small.svg) no-repeat center;
    width: 25px;
    height: 33px;
    opacity: 0;
    transition: all 0.218s;
  }
  .cb-navi-sticky.cb-navi-fixed,
  .cb-navi-sticky-clone.cb-navi-fixed {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 2;
  }
  .cb-navi-sticky.cb-navi-fixed .navi.sub2:before,
  .cb-navi-sticky-clone.cb-navi-fixed .navi.sub2:before {
    opacity: 1;
  }
  .cb-navi-sticky-clone {
    visibility: hidden;
  }
  /* LEVEL 1 SPRACHE */
  div.sub1 {
    font-size: 12px;
    font-size: 1.2rem;
    line-height: 1.25;
  }
  div.sub1 > .item .menu {
    padding: 24px 0 24px 3px;
    color: #9B9B9B;
    text-transform: uppercase;
  }
  div.sub1 > .item .menu:before {
    content: "/";
    margin-right: 3px;
  }
  div.sub1 > .item.init .menu:before {
    content: "";
  }
  /* LEVEL 2 */
  div.sub2 {
    font-size: 12px;
    font-size: 1.2rem;
    line-height: 1.25;
    letter-spacing: 1px;
  }
  div.sub2 > .item {
    position: relative;
  }
  div.sub2 > .item > .menu {
    position: relative;
    padding: 22px 10px 22px;
    color: #9B9B9B;
    text-transform: uppercase;
  }
  div.sub2 > .item > .menu:hover,
  div.sub2 > .item > .menu:focus {
    text-decoration: underline;
  }
  div.sub2 > .item > .menu:before {
    content: "";
    position: absolute;
    top: 21px;
    left: 0;
    width: 1px;
    height: 14px;
    background: #9B9B9B;
  }
  div.sub2 > .item > .menu.path {
    color: #005081;
    text-decoration: underline;
  }
  div.sub2 > .item.init > .menu {
    padding-left: 0;
  }
  div.sub2 > .item.init > .menu:before {
    content: none;
  }
  /* LEVEL 3 */
  div.sub3 {
    display: none;
    position: absolute;
    left: -1px;
    top: 58px;
    z-index: 3;
    background: #F5F5F5;
  }
  div.sub3 > .item .menu {
    min-width: 182px;
    padding: 19px 19px 17px;
    color: #9B9B9B;
    text-transform: uppercase;
  }
  div.sub3 > .item .menu:hover,
  div.sub3 > .item .menu:focus {
    text-decoration: underline;
  }
  div.sub3 > .item .menu.path {
    color: #005081;
    text-decoration: underline;
  }
  .item:hover > div.sub3 {
    display: block;
  }
}
@media only screen and (min-width: 1101px) and only screen and (min-width: 1480px) {
  .cb-navi-sticky > .navi,
  .cb-navi-sticky-clone > .navi {
    width: 1300px;
    margin-left: calc((100% - 1300px)/2);
  }
}
@media only screen and (min-width: 1101px) and (min-width: 1800px) {
  div.sub1 {
    font-size: 13px;
    font-size: 1.3rem;
    line-height: 1.30769231;
  }
  div.sub1 > .item .menu {
    padding: 22px 0 22px 3px;
  }
}
@media only screen and (min-width: 1101px) and only screen and (min-width: 1800px) {
  div.sub2 > .item > .menu {
    padding: 22px 15px 22px;
  }
}
@media only screen and (min-width: 1101px) and only screen and (min-width: 1800px) {
  div.sub2 > .item > .menu:before {
    top: 22px;
    height: 16px;
  }
}
@media only screen and (min-width: 1101px) and (min-width: 1800px) {
  div.sub2 {
    font-size: 13px;
    font-size: 1.3rem;
    line-height: 1.30769231;
  }
}
@media only screen and (min-width: 1101px) and only screen and (min-width: 1800px) {
  div.sub3 {
    top: 60px;
  }
}
@media only screen and (min-width: 1101px) and only screen and (min-width: 1800px) {
  div.sub3 > .item .menu {
    min-width: 220px;
  }
}
#home {
  width: 250px;
  margin-top: 20px;
  margin-bottom: 20px;
}
@media only screen and (min-width: 1800px) {
  #home {
    margin-top: 20px;
    margin-bottom: 65px;
  }
}
.desk {
  max-width: 1300px;
  width: 87.890625%;
  padding: 0 62px;
}
#wrapper {
  background-color: #F5F5F5;
}
.section--header {
  margin-top: 0;
  margin-bottom: 0;
}
.toplink.cb-goto-top-visible {
  bottom: 163px;
  right: 6.0546875%;
}
.section--norm .area,
.cb-layout2 .section--third .area,
.cb-layout3 .section--third .area {
  width: 100%;
}
.section--norm .area > .unit,
.cb-layout2 .section--third .area > .unit,
.cb-layout3 .section--third .area > .unit {
  margin-right: 1.61290323%;
  margin-left: 1.61290323%;
  width: 96.77419355%;
}
.section--norm .area h2,
.cb-layout2 .section--third .area h2,
.cb-layout3 .section--third .area h2,
.section--norm .area .foot,
.cb-layout2 .section--third .area .foot,
.cb-layout3 .section--third .area .foot {
  margin-right: 2.66666667%;
  margin-left: 2.66666667%;
}
.section--norm .area .part,
.cb-layout2 .section--third .area .part,
.cb-layout3 .section--third .area .part,
.section--norm .area > .grid table,
.cb-layout2 .section--third .area > .grid table,
.cb-layout3 .section--third .area > .grid table {
  margin-right: 2.66666667%;
  margin-left: 2.66666667%;
  width: 94.66666667%;
}
.section--norm .area .tiny,
.cb-layout2 .section--third .area .tiny,
.cb-layout3 .section--third .area .tiny {
  width: 44.66666667%;
}
.section--norm .area > .slim,
.cb-layout2 .section--third .area > .slim,
.cb-layout3 .section--third .area > .slim {
  width: 46.77419355%;
}
.section--norm .area > .slim h2,
.cb-layout2 .section--third .area > .slim h2,
.cb-layout3 .section--third .area > .slim h2,
.section--norm .area > .slim .foot,
.cb-layout2 .section--third .area > .slim .foot,
.cb-layout3 .section--third .area > .slim .foot,
.section--norm .area > .slim .part,
.cb-layout2 .section--third .area > .slim .part,
.cb-layout3 .section--third .area > .slim .part,
.section--norm .area > .slim.grid table,
.cb-layout2 .section--third .area > .slim.grid table,
.cb-layout3 .section--third .area > .slim.grid table {
  margin-right: 5.51724138%;
  margin-left: 5.51724138%;
}
.section--norm .area > .slim .part,
.cb-layout2 .section--third .area > .slim .part,
.cb-layout3 .section--third .area > .slim .part,
.section--norm .area > .slim.grid table,
.cb-layout2 .section--third .area > .slim.grid table,
.cb-layout3 .section--third .area > .slim.grid table {
  width: 88.96551724%;
}
.section--norm .area > .slim .tiny,
.cb-layout2 .section--third .area > .slim .tiny,
.cb-layout3 .section--third .area > .slim .tiny {
  width: 88.96551724%;
}
.section--separator .area {
  width: 100%;
}
.section--separator .area > .unit {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.section--separator .area h2,
.section--separator .area .foot {
  margin-right: 6.66666667%;
  margin-left: 6.66666667%;
}
.section--separator .area .part,
.section--separator .area > .grid table {
  margin-right: 6.66666667%;
  margin-left: 6.66666667%;
  width: 86.66666667%;
}
.section--separator .area > .slim .part,
.section--separator .area > .slim.grid table {
  width: 86.66666667%;
}
.cb-layout1 .section--third .area {
  width: 100%;
}
.cb-layout1 .section--third .area > .unit {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.cb-layout1 .section--third .area h2,
.cb-layout1 .section--third .area .foot {
  margin-right: 1.66666667%;
  margin-left: 1.66666667%;
}
.cb-layout1 .section--third .area .part,
.cb-layout1 .section--third .area > .grid table {
  margin-right: 1.66666667%;
  margin-left: 1.66666667%;
  width: 96.66666667%;
}
.cb-layout1 .section--third .area .tiny {
  width: 46.66666667%;
}
.cb-layout1 .section--third .area > .slim {
  width: 33.33333333%;
}
.cb-layout1 .section--third .area > .slim h2,
.cb-layout1 .section--third .area > .slim .foot,
.cb-layout1 .section--third .area > .slim .part,
.cb-layout1 .section--third .area > .slim.grid table {
  margin-right: 5%;
  margin-left: 5%;
}
.cb-layout1 .section--third .area > .slim .part,
.cb-layout1 .section--third .area > .slim.grid table {
  width: 90%;
}
.cb-layout1 .section--third .area > .slim .tiny {
  width: 90%;
}
.unit.form .tile .name,
.unit a.capt {
  float: left;
  width: 33%;
}
.unit.form .tile .chop,
.unit.form .tile textarea,
.unit.form .tile input.text,
.unit.form .tile .ctrl,
.unit input.capt {
  float: right;
  width: 63%;
}
.section--norm .area,
.cb-layout2 .section--third .area,
.cb-layout3 .section--third .area {
  width: 103.33333333%;
  margin-left: -1.66666667%;
  margin-right: -1.66666667%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-start;
}
.section--norm .area > .unit,
.cb-layout2 .section--third .area > .unit,
.cb-layout3 .section--third .area > .unit {
  padding: 0;
}
.section--norm .area > .unit .part,
.cb-layout2 .section--third .area > .unit .part,
.cb-layout3 .section--third .area > .unit .part {
  margin-top: 22px;
  margin-bottom: 22px;
}
@media only screen and (min-width: 1800px) {
  .section--norm .area > .unit .part,
  .cb-layout2 .section--third .area > .unit .part,
  .cb-layout3 .section--third .area > .unit .part {
    margin-top: 31px;
    margin-bottom: 31px;
  }
}
.section--norm .area > .unit .part.lead + .lead,
.cb-layout2 .section--third .area > .unit .part.lead + .lead,
.cb-layout3 .section--third .area > .unit .part.lead + .lead {
  margin-top: 40px;
}
.section--norm .area > .unit .part.pict,
.cb-layout2 .section--third .area > .unit .part.pict,
.cb-layout3 .section--third .area > .unit .part.pict {
  margin: 0;
  width: 100%;
}
.section--norm .area > .unit .part.link,
.cb-layout2 .section--third .area > .unit .part.link,
.cb-layout3 .section--third .area > .unit .part.link {
  margin-top: 20px;
  margin-bottom: 40px;
}
.section--norm .area > .unit.pure .part,
.cb-layout2 .section--third .area > .unit.pure .part,
.cb-layout3 .section--third .area > .unit.pure .part,
.section--norm .area > .unit.pure .foot,
.cb-layout2 .section--third .area > .unit.pure .foot,
.cb-layout3 .section--third .area > .unit.pure .foot {
  margin-right: 0;
  margin-left: 0;
}
.section--norm .area > .unit.pure.wide .part,
.cb-layout2 .section--third .area > .unit.pure.wide .part,
.cb-layout3 .section--third .area > .unit.pure.wide .part {
  width: 100%;
}
.section--norm .area > .unit.pure.wide .part.tiny,
.cb-layout2 .section--third .area > .unit.pure.wide .part.tiny,
.cb-layout3 .section--third .area > .unit.pure.wide .part.tiny {
  width: 50%;
}
.section--norm .area > .unit.pure.wide table,
.cb-layout2 .section--third .area > .unit.pure.wide table,
.cb-layout3 .section--third .area > .unit.pure.wide table {
  margin-right: -10px;
  margin-left: -10px;
  width: 100%;
}
.section--norm .area > .unit.pure.slim .part,
.cb-layout2 .section--third .area > .unit.pure.slim .part,
.cb-layout3 .section--third .area > .unit.pure.slim .part,
.section--norm .area > .unit.pure.slim .foot,
.cb-layout2 .section--third .area > .unit.pure.slim .foot,
.cb-layout3 .section--third .area > .unit.pure.slim .foot {
  width: 100%;
}
.section--norm .area > .unit.wide .part.tiny.pict,
.cb-layout2 .section--third .area > .unit.wide .part.tiny.pict,
.cb-layout3 .section--third .area > .unit.wide .part.tiny.pict {
  margin: 0 1.66666667% 0 0;
  width: 48.33333333%;
}
.section--norm .area > .unit.grid,
.cb-layout2 .section--third .area > .unit.grid,
.cb-layout3 .section--third .area > .unit.grid {
  margin-top: -20px;
}
.section--norm .area > .unit.grid table,
.cb-layout2 .section--third .area > .unit.grid table,
.cb-layout3 .section--third .area > .unit.grid table {
  margin: 0 1.66666667%;
  width: 96.88888889%;
}
.section--norm .area > .unit.form .part.lead + .lead,
.cb-layout2 .section--third .area > .unit.form .part.lead + .lead,
.cb-layout3 .section--third .area > .unit.form .part.lead + .lead {
  margin-top: 18px;
}
.section--norm .area > .unit.form .part.word,
.cb-layout2 .section--third .area > .unit.form .part.word,
.cb-layout3 .section--third .area > .unit.form .part.word {
  margin-top: 13px;
  margin-bottom: 13px;
}
.section--norm .area > .unit.form .part.tick,
.cb-layout2 .section--third .area > .unit.form .part.tick,
.cb-layout3 .section--third .area > .unit.form .part.tick {
  margin-top: 5px;
  margin-bottom: 0;
}
.section--norm .area.main > .unit:first-child,
.cb-layout2 .section--third .area.main > .unit:first-child,
.cb-layout3 .section--third .area.main > .unit:first-child {
  margin-top: 0;
}
.cb-layout1 .section--third .area {
  width: 103.33333333%;
  margin-left: -1.66666667%;
  margin-right: -1.66666667%;
}
.cb-layout1 .section--third .area > .unit .part {
  margin-top: 12px;
  margin-bottom: 12px;
}
.cb-layout1 .section--third .area > .unit .part.lead {
  margin-top: 15px;
}
.cb-layout1 .section--third .area > .unit .part.pict {
  margin-top: 0;
  margin-bottom: 0;
}
.cb-layout1 .section--third .area > .unit .part.link {
  margin-top: 35px;
}
.cb-layout1 .section--third .area > .unit.wide {
  width: 96.66666667%;
  margin-left: 1.66666667%;
  margin-right: 1.66666667%;
}
.cb-layout1 .section--third .area > .unit.wide .part.pict {
  margin: 0;
  width: 100%;
}
.cb-layout1 .section--third .area > .unit.wide .part.tiny.pict {
  margin: 0 1.66666667% 0 0;
  width: 48.33333333%;
}
.section--separator .area > .unit {
  float: none;
  padding: 85px 0;
}
.section--separator .area > .unit .part {
  margin-top: 15px;
  margin-bottom: 15px;
}
.section--separator .area > .unit .part.text + .lead {
  margin-bottom: 15px !important;
}
#disp {
  left: 50%;
  margin-left: -175px;
  width: 350px;
  font-size: 15px;
  font-size: 1.5rem;
  top: 120px !important;
}
#disp.zoom {
  left: 50%;
  margin-left: -385px;
  width: 770px;
}
#disp.tube {
  left: 50%;
  margin-left: -330px;
  width: 660px;
}
#disp.site > .body {
  overflow: auto;
  max-height: 400px;
}
/*#disp.site li,
div.cb-sitemap li {
  .font-size(14); 
}*/
h1,
h2,
h3,
h4 {
  overflow-wrap: normal;
  word-wrap: normal;
  -webkit-hyphens: none;
  -moz-hyphens: none;
  -ms-hyphens: none;
  hyphens: none;
}
h3 {
  font-size: 12px;
  font-size: 1.2rem;
  line-height: 1.25;
  letter-spacing: 2px;
}
@media only screen and (min-width: 1800px) {
  h3 {
    font-size: 14px;
    font-size: 1.4rem;
    line-height: 1.21428571;
  }
}
h4,
.area .pull .name {
  font-size: 24px;
  font-size: 2.4rem;
  line-height: 1.25;
  letter-spacing: 1.5px;
}
@media only screen and (min-width: 1800px) {
  h4,
  .area .pull .name {
    font-size: 34px;
    font-size: 3.4rem;
    line-height: 1.11111111;
  }
}
.link > .open,
.part.load > .load {
  padding: 7px 26px 7px;
  font-size: 15px;
  font-size: 1.5rem;
  line-height: 1.86666667;
  letter-spacing: 4px;
}
@media only screen and (min-width: 1800px) {
  .link > .open,
  .part.load > .load {
    padding: 17px 50px 17px;
    font-size: 19px;
    font-size: 1.9rem;
    line-height: 1.47368421;
  }
}
.section--norm,
.section--third {
  font-size: 16px;
  font-size: 1.6rem;
  line-height: 1.375;
}
@media only screen and (min-width: 1800px) {
  .section--norm,
  .section--third {
    font-size: 19px;
    font-size: 1.9rem;
    line-height: 1.42105263;
  }
}
.section--separator .area > .unit .part.text {
  font-size: 29px;
  font-size: 2.9rem;
  line-height: 1.31034483;
}
@media only screen and (min-width: 1800px) {
  .section--separator .area > .unit .part.text {
    font-size: 36px;
    font-size: 3.6rem;
    line-height: 1.16666667;
  }
}
.section--separator .area > .unit .part.text + .lead {
  margin-bottom: 15px !important;
}
.section--separator .area > .unit .part h3 {
  font-size: 14px;
  font-size: 1.4rem;
  line-height: 1.35714286;
}
@media only screen and (min-width: 1800px) {
  .section--separator .area > .unit .part h3 {
    font-size: 16px;
    font-size: 1.6rem;
    line-height: 1.375;
  }
}
.area .unit.grid table td {
  min-height: 100px;
}
@media only screen and (min-width: 1800px) {
  .area .unit.grid table th {
    font-size: 22px;
    font-size: 2.2rem;
    line-height: 1.36363636;
  }
  .area .unit.grid table td {
    padding: 14px;
  }
}
.section--last-content .desk {
  padding-bottom: 110px;
}
#servicesNavtop {
  font-size: 12px;
  font-size: 1.2rem;
  line-height: 1.25;
  letter-spacing: 1.4px;
}
#servicesNavtop a.cust {
  display: inline-block;
  padding: 24px 5px;
  color: #9B9B9B;
  text-transform: uppercase;
}
@media only screen and (min-width: 1800px) {
  #servicesNavtop {
    font-size: 13px;
    font-size: 1.3rem;
    line-height: 1.30769231;
  }
  #servicesNavtop a.cust {
    padding: 22px 15px;
  }
}
.section--footer .container--footernav__mobile {
  display: none;
}
.section--footer .container--footernav__desktop {
  display: block;
}
.section--footer {
  text-align: left;
  margin-top: 0;
  font-size: 17px;
  font-size: 1.7rem;
  line-height: 1.29411765;
}
@media only screen and (min-width: 1800px) {
  .section--footer {
    font-size: 20px;
    font-size: 2rem;
    line-height: 1.4;
  }
}
.section--footer .container--col {
  margin-top: 65px;
  margin-bottom: 65px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-start;
}
.section--footer .container--col .col {
  flex: 1 0 0;
}
.section--footer .container--footernav #servicesFooternav {
  width: 100%;
  margin: 85px auto 0;
}
.section--footer .container--footernav #servicesFooternav .meta {
  display: block;
  float: left;
  margin-top: 5px;
  margin-bottom: 5px;
  margin-right: 15px;
  padding: 14px 12px 9px;
  font-size: 12px;
  font-size: 1.2rem;
  line-height: 1.33333333;
  letter-spacing: 2px;
}
@media only screen and (min-width: 1800px) {
  .section--footer .container--footernav #servicesFooternav .meta {
    padding: 19px 18px 13px;
    font-size: 17px;
    font-size: 1.7rem;
    line-height: 1.23529412;
    letter-spacing: 2.12px;
  }
}
.section--footer .container--newsletter {
  margin-top: 25px;
  margin-bottom: 25px;
}
.section--footer .container--newsletter .service_footer-newsletter {
  padding: 14px 12px 9px;
  font-size: 12px;
  font-size: 1.2rem;
  line-height: 1.33333333;
  letter-spacing: 2px;
}
@media only screen and (min-width: 1800px) {
  .section--footer .container--newsletter .service_footer-newsletter {
    padding: 19px 18px 13px;
    font-size: 17px;
    font-size: 1.7rem;
    line-height: 1.23529412;
    letter-spacing: 2.12px;
  }
}
.section--footer .container--social #servicesSocial {
  float: right;
  margin-top: 10px;
  margin-bottom: 0;
}
.section--footer .container--imprint #servicesImprint {
  margin-top: 30px;
  margin-bottom: 20px;
}
.section--footer .container--imprint #servicesImprint .meta {
  float: left;
  display: inline-block;
}
@media only screen and (min-width: 1800px) {
  .section--footer .container--imprint #servicesImprint .meta {
    padding: 0 15px;
    font-size: 15px;
    font-size: 1.5rem;
    line-height: 1.4;
    letter-spacing: 2.12px;
  }
}
.section--footer .container--imprint #servicesImprint .meta:first-child {
  padding-left: 0;
  border-left: none;
}
.section--footer .logo {
  width: 250px;
  margin: 0;
}
@media only screen and (min-width: 1800px) {
  .section--footer .logo {
    width: 250px;
  }
}
.section--footer .vcard {
  margin-top: 29px;
}
.section--footer .vcard a.tel {
  margin-top: 22px;
}
#services {
  float: right;
  display: inline-block;
  margin-top: 5px;
}
@media only screen and (min-width: 1800px) {
  #services {
    margin-top: 10px;
    margin-bottom: 5px;
  }
}
#services .meta {
  margin-left: 29px;
}
@media only screen and (min-width: 1800px) {
  #services .meta {
    font-size: 15px;
    font-size: 1.5rem;
    line-height: 1.46666667;
  }
}
/*# sourceMappingURL=./screen-large.css.map */